import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "50",
  height: "51",
  viewBox: "0 0 50 51",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M44.059 7.85718H27.9842H21.8793H5.80443L0 16.1336H21.8793H27.9842H49.8634L44.059 7.85718Z",
      fill: "#212529"
    }, null, -1),
    _createElementVNode("path", {
      d: "M11.2947 17.7173H0L22.9036 43.844L11.2947 17.7173Z",
      fill: "#F06F02"
    }, null, -1),
    _createElementVNode("path", {
      d: "M26.9598 43.9943L50 17.7173H38.637L26.9598 43.9943Z",
      fill: "#F06F02"
    }, null, -1),
    _createElementVNode("path", {
      d: "M36.5747 17.7173H13.357L24.9659 43.8304L36.5747 17.7173Z",
      fill: "#F06F02"
    }, null, -1)
  ])))
}
export default { render: render }